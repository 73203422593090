<template>
  <v-row
    v-if="isAuthenticated"
    no-gutters
    class="mainWrapper"
  >
    <div
      class="title_container"
      style="display:flex;justify-content: space-between"
    >
      <tab-header
        class="mb-2 mx-auto mx-md-0"
        icon-name="credit-card-outline"
        text="Мої повідомлення"
      />
      <v-col
        class="pa-0 col-sm-auto mt-3 mt-sm-0 go_out-btn"
        style="align-self: center"
      >
        <v-btn
          block
          outlined
          rounded
          color="primary"
          @click="requestNewMessages"
        >
          <slot name="cancel-btn">
            оновити
          </slot>
        </v-btn>
      </v-col>
    </div>
    <template v-if="messages.length === 0">
      <p class="text--text text-h6 text-center text-sm-start">
        У Вас поки що немає повідомлень
      </p>
    </template>
    <h4 v-else>
      загальна кількість:{{ " "+ messages.length }}
    </h4>
    <div class="text-center">
      <v-pagination
        v-model="page"
        color="#12b423"
        :length="numberOfPages"
        circle
      />
    </div>
    <PopUpWindow v-if="showPopupWithMessage">
      <InfoMessagePopUp
        :slides="currentMessage"
        @PopUpclose="popUpToogle"
      />
    </PopUpWindow>
    <notification
      v-if="errorNotificationMessage"
      type="warning"
    >
      Нажаль ми не змогли знайти це повідомлення, спробуйте ще раз.
    </notification>
    <v-main v-if="messages.length > 0">
      <div
        class=" vContainer"
        fluid
      >
        <v-col>
          <v-card>
            <v-list two-line>
              <template v-for="n in 1">
                <v-list-item :key="n">
                  <message
                    :messages="messagesForCurrentPage"
                    @onPopUpClose="popUpToogle"
                  />
                </v-list-item>

                <v-divider
                  v-if="n !== 6"
                  :key="`divider-${n}`"
                  inset
                />
              </template>
            </v-list>
          </v-card>
        </v-col>
      </div>
    </v-main>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import blockPreloaderMixin from '@/mixins/blockPreloaderMixin';
import TabHeader from '@/components/common/TabHeader';
import Message from './message';
import PopUpWindow from '../../components/common/PopUpWindow';
import InfoMessagePopUp from '../../components/common/InfoWindowModal';
import downloadDocServiceMixin from '../../mixins/downloadDocServiceMixin';
import notification from '../../components/common/Notification';
import errorNotificationMixin from '../../mixins/errorNotificationMixin';

export default {
  name: 'SystemMessages',
  components: {
    TabHeader, Message, PopUpWindow, InfoMessagePopUp, notification,
  },
  mixins: [blockPreloaderMixin, downloadDocServiceMixin, errorNotificationMixin],
  title: 'Повідомлення',
  data() {
    return {
      showPopupWithMessage: false,
      errorNotificationMessage: false,
      currentMessage: [],
      linkMessageId: '',
      page: 1,
      numberOfmessages: 5,
    };
  },
  computed: {
    ...mapGetters('auth', [
      'isAuthenticated',
    ]),
    ...mapGetters('userMessages', [
      'messages',
      'requestedMessage',
    ]),
    numberOfPages() {
      return Math.ceil(this.messages.length / this.numberOfmessages);
    },
    numberOfFullPages() {
      return Math.floor(this.messages.length / this.numberOfmessages);
    },
    // eslint-disable-next-line vue/return-in-computed-property
    messagesForCurrentPage() {
      const topPage = this.page * this.numberOfmessages > this.messages.length
        ? this.messages.length : this.page * this.numberOfmessages;
      const numberOfElementsOnlastPage = this.messages.length - this.numberOfFullPages
        * this.numberOfmessages;
      let bottomPage = topPage - this.numberOfmessages;
      if (this.page === this.numberOfPages && this.messages.length > this.numberOfFullPages
        * this.numberOfmessages) {
        bottomPage = topPage - numberOfElementsOnlastPage;
      }
      const shownPageOfMessages = [];
      // eslint-disable-next-line no-plusplus
      for (let i = bottomPage; i < topPage; i++) {
        shownPageOfMessages.push(this.messages[i]);
      }
      return shownPageOfMessages;
    },
  },
  beforeMount() {
    this.getAllUserMessages();
    if (this.$route.query.message) {
      this.linkMessageId = `${this.$route.query.message}`;
      this.requestForExactMessage({ id: this.linkMessageId })
        .then((data) => {
          if (data.payload !== null) {
            this.currentMessage = this.requestedMessage;
            this.showPopupWithMessage = true;
          } else {
            this.errorNotificationMessage = true;
            const timer = setTimeout(() => {
              clearTimeout(timer);
              this.errorNotificationMessage = false;
            }, 7000);
          }
        }).catch(() => {
          this.errorNotificationMessage = true;
          const timer = setTimeout(() => {
            clearTimeout(timer);
            this.errorNotificationMessage = false;
          }, 7000);
        });
    }
  },
  mounted() {
    if (this.$route.query.token !== undefined) {
      const { token } = this.$route.query;
      this.downloadDoc(token);
    }
  },
  methods: {
    ...mapActions('userMessages', [
      'getAllUserMessages',
      'requestForNewMessages',
      'requestForExactMessage',
    ]),
    popUpToogle(message) {
      this.currentMessage = [message];
      this.showPopupWithMessage = !this.showPopupWithMessage;
    },
    updateForNewMessagesHandler() {
      this.requestForNewMessages();
    },
    requestNewMessages() {
      this.requestForNewMessages();
    },
  },
};
</script>

<style lang="scss" scoped>
.mainWrapper {
  display: flex;
  flex-direction: column;
  .card-wrapper {
    width: 100%;
  }
}
.vContainer {
  margin-top: 20px;
}
.vContainer::-webkit-scrollbar {
  display: none;
}

.title_container{
  margin-bottom: 20px;
}
@media (max-width: 600px) {
  .title_container{
    flex-direction: column;
  }
}
</style>
