<template>
  <div class="general_container">
    <v-card
      v-for="(message, index) of messages"
      :key="index"
      elevation="4"
      outlined
      :style="{ 'border': message.is_read? '2px solid #cecbcb':'2px solid green'}"
      class="general_wrapper"
    >
      <v-card-title :class="['main-title', {'disable_title': message.is_read === true}]">
        {{ message.title }}
      </v-card-title>
      <v-icon
        v-if="message.is_pay_info"
        color="light-grey"
        size="50px"
        class="typeOfMessageIcon"
      >
        mdi-cash-multiple
      </v-icon>
      <v-icon
        v-if="message.is_pay_info && message.is_read !== true"
        color="#12b423"
        size="50px"
        class="typeOfMessageIcon"
      >
        mdi-cash-multiple
      </v-icon>
      <v-icon
        v-else-if="!message.is_pay_info && message.is_read === true"
        class="typeOfMessageIcon"
        color="light-grey"
        size="50px"
      >
        mdi-information-outline
      </v-icon>
      <v-icon
        v-else
        class="typeOfMessageIcon"
        color="#12b423"
        size="50px"
      >
        mdi-information-outline
      </v-icon>
      <v-card-text
        v-if="message.text_message.length < 90"
        class="pb-0 main-text"
        v-html="message.text_message"
      >
<!--        {{ message.textMessage }}-->
      </v-card-text>
      <v-card-text
        v-else
        class="pb-0 main-text"
        v-html= messageCutter(message.text_message)
      >
<!--        {{ messageCutter(message.textMessage) }}-->
      </v-card-text>
      <div class="actions_wrapper">
        <h5 class="dateBlock">
          {{ dateFormatter(message.created_at) }}
        </h5>
        <v-card-actions style="justify-content: center">
          <v-btn
            v-if="message.is_read"
            color="#727272"
            class="ma-2 white--text buttonMessage"
            @click="openMessageHandler(message)"
          >
            переглянути
            <v-icon
              right
              dark
            >
              mdi-email-open-multiple-outline
            </v-icon>
          </v-btn>
          <v-btn
            v-else
            color="#55945BFF"
            class="ma-2 white--text buttonMessage"
            @click="openMessageHandler(message)"
          >
            переглянути
            <v-icon
              right
              dark
            >
              mdi-email-open-multiple-outline
            </v-icon>
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </div>
</template>

<script>

import { mapActions } from 'vuex';
import formatDateMixin from '../../mixins/formatDateMixin';

export default {
  name: 'Message',
  title: 'Повідомлення',
  data() {
    return {
      isPopUpOpened: false,
      cuttedMesage: '',
    };
  },
  mixins: [formatDateMixin],
  computed: {},
  beforeMount() {
    this.getReferenceBookStore('DICT_COMM_MODULE_REFLECTION_TYPE');
  },
  methods: {
    ...mapActions(['getReferenceBookStore']),
    openMessageHandler(message) {
      this.$emit('onPopUpClose', message);
    },
    dateFormatter(date) {
      const formDate = this.dateParser(date);
      return formDate;
    },
    messageCutter(message) {
      let sliced = message.slice(0, 66);
      if (sliced.length < message.length) {
        sliced += '...';
      }
      return sliced;
    },
  },

  props: {
    messages: {
      type: Array,
    },
  },
};
</script>

<style lang="scss" scoped>
.general_container{
  width: 100%;
}
.general_wrapper{
  margin-bottom: 20px;
  border-radius: 10px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .main-title{
  max-width: 100%;
  padding-bottom: 0px;
  word-break: keep-all;
}
  .main-text{
    max-width: 70%;
    padding-bottom: 0px;
    word-break: keep-all;
  }

  @media screen and (max-width: 740px) {
    .main-text{
      max-width: 90%;
      word-break: keep-all;
    }
  }
  @media screen and (max-width: 420px) {
    .main-title{
      line-height: normal;
    }
  }
.disable_title{
  color: #727272;
}
.actions_wrapper{
  display: flex;
  justify-content: space-between;
}
}
.typeOfMessageIcon{
  display: block;
  position: absolute;
  top: 10px;
  right: 20px;
}
.dateBlock{
  align-self:center;
  margin-left: 15px;
  color: #645a1d;
  margin-top: 10px;
}
@media (max-width: 500px) {
  .typeOfMessageIcon{
    display: none;
  }
  .dateBlock{
    margin-left: 0;
  }
}
@media (max-width: 400px) {
 .actions_wrapper{
   flex-direction: column;
 }
  .buttonMessage{
    margin:0 auto;
  }
}
</style>
